<template>
  <div id="about">
    <div class="banner">
      <div class="grad">
        <div class="item one">
          <img :src="`img/item1.png`" alt="" />
          <div class="txt">创意与技术并重</div>
        </div>
        <div class="item two">
          <img :src="`img/item2.png`" alt="" />
          <div class="txt">一站式服务</div>
        </div>
        <div class="item three">
          <img :src="`img/item3.png`" alt="" />
          <div class="txt">资深设计团队</div>
        </div>
        <div class="item">
          <img :src="`img/item4.png`" alt="" />
          <div class="txt">产品视觉化</div>
        </div>
        <div class="item five">
          <img :src="`img/item5.png`" alt="" />
          <div class="txt">严苛细节标准</div>
        </div>
        <div class="item">
          <div class="txt">MORE</div>
        </div>
      </div>
    </div>
    <div class="intro">
      <div class="title">专注产品视觉化专攻影视特效制作</div>
      <div class="line"></div>
      <div class="info">
        康斐互动是⼀家专注产品视觉化的影视特效公司<br />
        以先进、精细化的影视特效技术，贯穿于独立、新颖的创意之中，凸显产品卖点及潜在优势<br />
        为客户在当下市场竞争中提供实效、有⽤且不失⾃自主调性的优质视觉呈现<br />
        医疗大健康领域的准确切⼊令康菲的优势不断提升，也凭借优异的技术攻坚与持续的⼈<br />
        人才导流在众多竞争者中脱颖⽽出
      </div>
    </div>
    <div class="serverTitle">
      <span data-v-56b687e3="">日常作业流程</span>
      <div class="line"></div>
    </div>
    <div class="work">
      <div class="one">
        <div class="item">
          <div class="num">1</div>
          <div class="name">Start</div>
          <div class="info">
            与客户见面，洽谈合作<br />
            事宜，营销任务、节点<br />
            计划、创意需求等
          </div>
        </div>
        <div class="item">
          <div class="num">2</div>
          <div class="name">Brief</div>
          <div class="info">
            客户部等前端人员理解<br />
            本次任务，并梳理会议<br />
            纪要、客户简报
          </div>
        </div>
        <div class="item">
          <div class="num">3</div>
          <div class="name">Meetting</div>
          <div class="info">
            召集项目启动会，向团 <br />队成员明确任务目标、<br />
            时间节点、作业要求等
          </div>
        </div>
        <div class="item">
          <div class="num">4</div>
          <div class="name">Team</div>
          <div class="info">
            成立项目专案组，<br />
            明确成员组成结构
          </div>
        </div>
        <div class="item">
          <div class="num">5</div>
          <div class="name">Brain storm</div>
          <div class="info">
            CD召集创意会，输出Big<br />
            idea，并由团队辅助提出<br />
            延展创意发想
          </div>
        </div>
      </div>
      <div class="one">
        <div class="item">
          <div class="num">6</div>
          <div class="name">Traffic</div>
          <div class="info">
            团队成员分工，明确任 <br />务和时间，责任到人，<br />
            出具倒排表
          </div>
        </div>
        <div class="item">
          <div class="num">7</div>
          <div class="name">Proposal</div>
          <div class="info">
            创意成案，团队成 <br />员向客户提案汇报，<br />
            并沟通交流意见
          </div>
        </div>
        <div class="item">
          <div class="num">8</div>
          <div class="name">List</div>
          <div class="info">
            双方达成一致，按需求<br />
            出具整体或阶段推进工<br />
            作清单
          </div>
        </div>
        <div class="item">
          <div class="num">9</div>
          <div class="name">Achievements</div>
          <div class="info">
            作业贡献出品，向<br />
            客户提交成果
          </div>
        </div>
        <div class="item">
          <div class="num">10</div>
          <div class="name">The End</div>
          <div class="info">
            任务完成，总结工作<br />
            并开展后续探讨
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped  rel="stylesheet/stylus">
#about {
  margin-bottom: -95px;
  background-color: #F1F1F1;
}

.banner {
  width: 100%;
  height: 823px;
  background: url('../../public/img/aboutBanner.png') no-repeat center top;
  background-size: auto 823px;

  .grad {
    width: 1240px;
    margin: 0 auto;
    padding: 133px 46px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .item {
      width: 382px;
      height: 230px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #FFFFFF;
      box-sizing: border-box;

      img {
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
      }

      &.one {
        border-bottom: 1px solid #ffffff;
      }

      &.two {
        border: 1px solid #ffffff;
        border-top: none;
      }

      &.three {
        border-bottom: 1px solid #ffffff;
      }

      &.five {
        border-right: 1px solid #ffffff;
        border-left: 1px solid #ffffff;
      }
    }
  }
}

.intro {
  width: 1240px;
  margin: -95px auto 0;
  height: 511px;
  background-color: #ffffff;
  text-align: center;

  .title {
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #111111;
    padding-top: 94px;
    margin-bottom: 25px;
  }

  .line {
    width: 82px;
    height: 4px;
    background: #57A633;
    margin: 0 auto 25px;
  }

  .info {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #111111;
    line-height: 47px;
  }
}

.serverTitle {
  position: relative;
  width: 303px;
  text-align: center;
  margin: 92px auto 66px;

  span {
    position: relative;
    z-index: 3;
    font-size: 48px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #292929;
  }

  .line {
    position: absolute;
    top: 30px;
    left: -9px;
    width: 325px;
    height: 15px;
    background: #57A633;
  }
}

.work {
  width: 1240px;
  margin: 0 auto;
  padding-bottom: 110px;

  .one {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .item {
      width: 239px;
      height: 231px;
      background: #FFFFFF;
      box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.09);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;
      box-sizing: border-box;

      .num {
        width: 66px;
        height: 66px;
        background: #F7F7F7;
        border-radius: 50%;
        text-align: center;
        line-height: 66px;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #57A633;
        margin-bottom: 15px;
      }

      .name {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #444444;
        line-height: 18px;
        margin-bottom: 25px;
      }

      .info {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}

</style>